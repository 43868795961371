import React, { useEffect } from "react"
import Loading from "./Loading"

const TwitterPublish = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://platform.twitter.com/widgets.js"
    if (document.getElementsByClassName("twitter-embed")[0]) {
      document.getElementsByClassName("twitter-embed")[0].appendChild(script)
    }
  }, [])

  return (
    <section className="twitterContainer">
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-height="500"
          href="https://twitter.com/skipthedepot?ref_src=twsrc%5Etfw"
          data-chrome="noheader nofooter noborders"
        >
          <Loading size="5" />
        </a>
      </div>
    </section>
  )
}

export default TwitterPublish
