import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import TextBlock from "../components/TextBlock"
import SecondaryList from "../components/SecondaryList"
import CtaHeading from "../components/CtaHeading"
import Cta from "../components/Cta"

const Header = styled.div`
  position: relative;
  background: url(/img/svg/pattern.svg) center center/15%,
    linear-gradient(to right, #2c5364, #203a43, #0f2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  @media only screen and (max-width: 56.25em) {
    background: url(/img/svg/pattern.svg) center center/25%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
  @media only screen and (max-width: 40em) {
    background: url(/img/svg/pattern.svg) center center/45%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
  @media only screen and (max-width: 32.5em) {
    background: url(/img/svg/pattern.svg) center center/65%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
    padding: 3rem;
  }
  height: 7rem;
`

const Content = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 auto;
  max-width: 130rem;
  @media only screen and (max-width: 52em) {
    grid-template-columns: 1fr;
  }
`

const Line = styled.div`
  width: 95%;
  height: 2px;
  margin: 0 auto;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(200, 200, 200, 0.8) 50%,
    rgba(0, 0, 0, 0) 100%
  );
`

const Space = styled.div`
  height: ${(props) => props.height || "3rem"};
`

export const BlogPostTemplate = ({
  title,
  friendlyTitle,
  headingImage,
  date,
  description,
  tags,
  html,
  posts,
  breadcrumbs,
  url,
  isCms,
}) => {
  const [details, setDetails] = useState({})

  function handleChange(event) {
    setDetails({ ...details, [event.target.name]: event.target.value })
  }

  return (
    <>
      <Header />
      <Breadcrumbs data={breadcrumbs} />
      <Content>
        <TextBlock
          title={title}
          friendlyTitle={friendlyTitle}
          headingImage={headingImage}
          date={date}
          description={description}
          tags={tags}
          data={html}
          url={url}
        />
        <SecondaryList
          data={posts}
          details={details}
          handleChange={handleChange}
          isCms={isCms}
        />
      </Content>
      <Cta href="https://app.skipthedepot.com/register">Get Started</Cta>
      <Space height="6rem" />
      <Line />
      <CtaHeading
        title="Interested in driving for us?"
        subtitle="Get in contact with us to start driving!"
      />
      <Cta variant to="/contact/drive-with-us">
        Start driving
      </Cta>
      <Space />
    </>
  )
}

const BlogPost = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: {
        title,
        friendlyTitle,
        headingImage,
        date,
        description,
        tags,
      },
    },
    allMarkdownRemark: { edges: posts },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")

  const seoDesc = `SkipTheDepot picks up your cans, electronics, and clothes and e-Transfers you the refund. ${title}`
  return (
    <Layout
      title={`${title} | SkipTheDepot | Book A Pickup Today`}
      seoDesc={seoDesc}
    >
      <BlogPostTemplate
        title={title}
        friendlyTitle={friendlyTitle}
        headingImage={headingImage}
        date={date}
        description={description}
        tags={tags}
        html={html}
        posts={posts}
        breadcrumbs={breadcrumbs}
        url={location.href}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        friendlyTitle
        date(formatString: "MMMM Do, YYYY")
        description
        tags
        headingImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            publish
            title
            friendlyTitle
            date(formatString: "MMM Do, YYYY")
            headingImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
