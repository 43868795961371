import React from "react"
import styled from "@emotion/styled"


const Container = styled.section`
  padding: 3rem 3rem 0;
  margin: 2.5rem auto;
  position: relative;
  max-width: 130rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Body = styled.p`
  text-align: center;
  font-weight: 400;
  padding-top: 1.5rem;
`

const Ready = ({ title, subtitle }) => (
  <Container>
    <Primary>{title}</Primary>
    <Body>{subtitle}</Body>
  </Container>
)

export default Ready
