import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { createHTML } from "../utils"

import Img from "gatsby-image"
import ShareButtons from "./ShareButtons"

const Container = styled.section`
  max-width: 90rem;
  padding: 3rem;
  margin: 0 auto;
`

const Image = styled(Img)`
  max-width: 100%;
  object-fit: contain;
`

const PreviewImage = styled.img`
  max-width: 100%;
  object-fit: contain;
`

const Tag = styled(Link)`
  font-size: 1.5rem;
  padding: 0.5rem;
  margin: 1rem 1rem 0 0;
  border-radius: 10px;
  background-color: #707070;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
`

const H1 = styled.h1`
  font-size: 3.5rem;
`

const H2 = styled.h2`
  font-size: 1.9rem;
  color: #808080;
  font-weight: 400;
  padding-bottom: 3rem;
  a {
    color: #2196f3;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Body = styled.div`
  padding: 3rem 0;
  h2 {
    font-size: 2.5rem;
    color: #2196f3;
    background: linear-gradient(to right, #2196f3, #2dc7ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    padding-top: 3rem;
  }
  h3 {
    font-size: 2rem;
    color: #505050;
    padding-top: 1.5rem;
  }
  p {
    padding: 1rem 0;
  }
  a {
    color: #2196f3;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ol {
    padding-top: 2rem;
    padding-left: 1.75rem;
  }
  ul {
    padding-top: 2rem;
    padding-left: 1.75rem;
  }
  img {
    object-fit: contain;
    max-width: 100%;
  }
  table {
    border-collapse: collapse;
  }
  th {
    padding: 0.5rem 1.5rem;
    border: 1px solid #dfe2e5;
  }
  td {
    padding: 0.5rem 1.5rem;
    border: 1px solid #dfe2e5;
  }
  blockquote {
    margin-top: 1rem;
    padding-left: 3rem;
    border-left: 2px solid #dfe2e5;
  }
  code {
    font-size: 1.7rem;
    font-style: oblique;
  }

  iframe {
    width: 100%;
  }
`

const Share = styled.div`
  text-align: center;
`

const TextBlock = ({
  data,
  title,
  friendlyTitle,
  headingImage,
  date,
  description,
  tags,
  url,
}) => {
  return (
    <Container>
      <H1>{friendlyTitle || title}</H1>
      <H2 dangerouslySetInnerHTML={createHTML(description)} />
      {headingImage ? (
        headingImage.childImageSharp ? (
          <Image
            fluid={headingImage.childImageSharp.fluid}
            alt="Featured Image"
          />
        ) : (
          <PreviewImage src={headingImage} alt="Featured image" />
        )
      ) : null}

      {console.log("data", data)}

      <Body dangerouslySetInnerHTML={createHTML(data)} />
      <Share>
        Share this article
        <ShareButtons height={20} textcolor="#999" url={url} />
      </Share>

      {(tags || []).map((tag, count) => (
        <Tag to={`/blog?q=${tag}`} key={count}>
          {tag}
        </Tag>
      ))}
    </Container>
  )
}

export default TextBlock
