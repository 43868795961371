import React, { useEffect } from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ExternalLink = styled.a`
  margin: 0 1.5rem;
`

const Svg = styled.img``

const FBWrapper = styled.div`
  span {
    vertical-align: unset !important;
  }
`

const ShareButtons = ({ height, url }) => {
  useEffect(() => {
    var js,
      fjs = document.getElementsByTagName("script")[0]
    if (document.getElementById("facebook-jssdk")) return
    js = document.createElement("script")
    js["facebook-jssdk"] = "facebook-jssdk"
    js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0"
    fjs.parentNode.insertBefore(js, fjs)
  }, [])

  return (
    <>
      <Container>
        <ExternalLink
          href={`https://twitter.com/intent/tweet?text=Check%20out%20SkipTheDepot%21&url=${encodeURI(
            url
          )}`}
          target="__blank"
        >
          <Svg
            src="/img/svg/twitter.svg"
            alt="Twitter logo"
            height={height}
            width={height}
          />
        </ExternalLink>
        <ExternalLink
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
            url
          )}&title=Check%20out%20SkipTheDepot%21`}
          target="__blank"
        >
          <Svg
            src="/img/svg/linkedin.svg"
            alt="LinkedIn logo"
            height={height}
            width={height}
          />
        </ExternalLink>

        <FBWrapper>
          <div
            className="fb-share-button"
            data-href={url}
            data-layout="button"
          />
        </FBWrapper>
      </Container>
    </>
  )
}

export default ShareButtons
