import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Loading from "./Loading"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-gap: 1rem;
`

const PostOuter = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`

const PostInner = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Image = styled(Img)`
  width: 100%;
  height: 100%;

  &:hover {
    filter: brightness(75%);
  }
`

const LoadingWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  align-self: center;
`

const InstagramFeed = () => {
  const data = useStaticQuery(graphql`
    query IgQuery {
      allInstaNode(limit: 9, sort: { fields: timestamp, order: DESC }) {
        edges {
          node {
            id
            permalink
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    allInstaNode: { edges },
  } = data

  return (
    <Container>
      {edges ? (
        edges.map(
          ({
            node: {
              id,
              permalink,
              localFile: { childImageSharp },
            },
          }) => {
            return (
              <PostOuter>
                <PostInner key={id} href={permalink} target="__blank">
                  <Image fluid={childImageSharp.fluid} />
                </PostInner>
              </PostOuter>
            )
          }
        )
      ) : (
        <LoadingWrapper>
          <Loading size="5" />
        </LoadingWrapper>
      )}
    </Container>
  )
}

export default InstagramFeed
